import React, {createContext, useContext, useReducer} from 'react'
import { distributionReducer } from './reducer'

const initialState = {
    distributions: [],
    isLoadingApi: false
}


export const DistributionContext = createContext()

export const useDistributionContext = () => {
    const context = useContext(DistributionContext)

    if (context === undefined) {
        throw new Error('Distribution Context must be used under Distribution Context Provider')
    }

    return context
}

export const DistributionContextProvider = (props) => {
    const [distributionState, distributionDispatch] = useReducer(distributionReducer,  initialState)


    return (
        <DistributionContext.Provider
         value={{ distributionState,  distributionDispatch}}
        >
            {props.children}
        </DistributionContext.Provider>
    )
}