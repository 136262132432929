import React, { useContext, createContext, useReducer } from 'react'
import { userReportReducer } from './reducer'


const initialState = {
    user: [],
    isLoadingApi: false
}
export const UserReportContext = createContext()

export const useReportContext = () => {
    const context = useContext(UserReportContext)

    if (context === undefined) {
        throw new Error('useReportContext must used under UserReportContextProvider')
    }

    return context 
}


export const UserReportContextProvider = (props) => {
    const [userReportState, userReportDispatch] = useReducer(userReportReducer, initialState)

    return (
         <UserReportContext.Provider
         value={{ userReportState,  userReportDispatch}}
         >
             {
                 props.children
             }
         </UserReportContext.Provider>
    )
}