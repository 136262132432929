// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import rewards from '@src/views/rewards/store/reducer'
import fees from '@src/views/fees/store/reducer'
import websites from '@src/views/websites/store/reducer'
import campaigns from '@src/views/campaigns/store/reducer'
import answers from '@src/views/answers-report/store/reducer'
import ethAddress from './eth'
// import tokens from '@src/views/tokens/store/reducer'
// import settings from '@src/views/settings/store/reducer'
// import reports from '@src/views/reports/store/reducer'
import maintenance from '@src/views/maintenance/store/reducer'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  rewards,
  fees,
  websites,
  campaigns,
  answers,
  ethAddress,
  maintenance
  // tokens,
  // settings,
  // reports
})

export default rootReducer
