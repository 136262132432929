import React, {createContext, useContext, useReducer} from 'react'
import {surveyReducer} from './reducer'

const intialState = {
    question: [],
    qr:'',
    freebie: [],
    isLoading: false
}
export const SurveyContext = createContext()

export const useSurvey =  () => {
    const context =  useContext(SurveyContext)

    if (context === undefined) {
        throw new Error('useSurvey must be under SurveyContextProvider')
    }

    return context
}

export const SurveyContextProvider = (props) => {
    const [surveyState, surveyDispatch] = useReducer(surveyReducer, intialState)
    return (
        <SurveyContext.Provider
            value={{ surveyState, surveyDispatch }}
        >
        {props.children}
        </SurveyContext.Provider>
    )
}