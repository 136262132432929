const initialEthAddress = () => {
  const item = window.localStorage.getItem('ethAddress')
  //** Parse stored json or if none return initialValue
  return item ? item : null
}

// ** Initial State
const initialState = {
  ethAddress: initialEthAddress()
}
const ethAddress = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ETH_ADDRESS':
      if (!initialEthAddress()) window.localStorage.setItem('ethAddress', action.value)
      return { ...state, ethAddress: action.value }
    case 'RESET_ETH_ADDRESS':
      if (initialEthAddress()) window.localStorage.removeItem('ethAddress')
      return { ...state, ethAddress: null }
    default:
      return { ...state }
  }
}
export default ethAddress
