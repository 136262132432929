export const userReportReducer = (state, action) => {
    switch (action.type) {
        case "IS_LOADING": 
            return {
                ...state,
                isLoadingApi: true
            }
            break
        case "FETCH_USERS":
            return {
                ...state,
                user: action.payload,
                isLoadingApi: false
            }
            break
        default: 
        throw new Error(`${action.type} is not defined on reducer`)
    }
}