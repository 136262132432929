import React, {createContext, useContext, useReducer} from 'react'
import { transactionReducer } from './reducer'

const initialState = {
    transactions: [],
    isLoadingApi: false
}


export const TransactionContext = createContext()

export const useTransactionContext = () => {
    const context = useContext(TransactionContext)

    if (context === undefined) {
        throw new Error('Transaction Context must be used under Transaction Context Provider')
    }

    return context
}

export const TransactionContextProvider = (props) => {
    const [transactionState, transactionDispatch] = useReducer(transactionReducer,  initialState)


    return (
        <TransactionContext.Provider
         value={{ transactionState,  transactionDispatch}}
        >
            {props.children}
        </TransactionContext.Provider>
    )
}