const initialState = {
    data: null
    
}

const maintenance = (state = initialState, action) => {
    switch (action.type) {
        case 'MAINTENANCE_MODE':
            return {...state, data: action.data}
        default:
            return {...state}
    }
}

export default maintenance