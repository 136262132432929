export const surveyReducer = (state, action) => {
    switch (action.type) {
        case "GET_QUESTION":
            return {
                ...state,
                question: action.questions
            }
        default : {
            throw new Error(`${action.type} not declared on reducer`)
        }
    }
}